<template>
    <div>
        <div class="content-block-outlined">
            <header class="content-default">
                <h2 class="heading-secondary">Email me my results.</h2>
            </header>
            <form class="direct-mail" v-on:submit.prevent="notify">
                <div class="messaging"
                     v-bind:class="{
                            success: notifyStatus === 'success',
                            error: notifyStatus === 'error'
                        }"
                >
                    <p v-if="notifyStatus === 'success'">
                        Your results have been sent!
                    </p>
                    <p v-if="notifyStatus === 'error'">
                        There has been an error sending your request. Please contact Forth
                    </p>
                </div>
                <input v-model="name" type="text" placeholder="Name" class="name" required="required"/>
                <input v-model="email" type="email" placeholder="Email" class="email" required="required"/>
                <button class="submit" type="submit">
                    <span class="screen-reader-text">Submit email form</span>
                </button>
                <label class="checkbox">
                    <input v-model="newsletter" type="checkbox" value="1" name="newsletter" />
                    <div class="toggle-indicator"></div>
                    Yes! Send me news from Forth on the future of getting around.
                </label>
                <button
                    class="submit narrow" type="submit"
                >
                    <span class="screen-reader-text">Submit email form</span>
                </button>
            </form>
        </div>
    </div>
</template>
<script>
  import get from 'lodash/get';

  export default{
    props: {
      vehicleSlugs: String,
      config: Object,
      used: Boolean
    },
    data() {
      return {
        name: '',
        email: '',
        newsletter: false,
        notifyStatus: ''
      }
    },
    computed: {
      version: function() {
        // Start with a default results url
        let version = 'default';

        // Reset with custom results url from page if it exists
        if (get(this.config, 'version') !== undefined) {
          version = this.config.version;
        }

        return version;
      }
    },
    methods: {
      notify: function() {
        // Reset status
        this.notifyStatus = '';

        const request = new window.XMLHttpRequest();
        request.open('POST', 'api/v1/matchNotify', true);
        request.setRequestHeader(
          'Content-Type',
          'application/x-www-form-urlencoded; charset=UTF-8'
        );
        const params =
          `version=${encodeURIComponent(this.version)}` +
          `&slugs=${encodeURIComponent(this.vehicleSlugs)}` +
          `&email=${encodeURIComponent(this.email)}` +
          `&name=${encodeURIComponent(this.name)}` +
          `&signup=${encodeURIComponent(this.newsletter)}` +
          `&used=${encodeURIComponent(this.used)}`;

        // Call a function when the state changes.
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            // Success!
            this.notifyStatus = 'success';
          } else {
            this.notifyStatus = 'error';
          }
        }

        request.send(params);
      }
    }
  }
</script>
