import debounce from "lodash/debounce";

export default class NavDropdown {
  constructor(el) {
    this.el = el;
    this.dropdowns = el.querySelectorAll('[data-ref="dropdown"]');

    this.bindEvents();
    this.setPosition();
  }

  bindEvents() {
    window.addEventListener("resize", debounce(this.setPosition, 300));
  }

  setPosition = () => {
    this.dropdowns.forEach(this.setItemPosition);
  };

  setItemPosition = (item) => {
    const boundaries = item.getBoundingClientRect();

    // Align to the right if dropdown will get cut off by browser window
    if (boundaries.right > window.innerWidth) {
      item.setAttribute("style", "right: 0;");
    } else if (boundaries.right + boundaries.width < window.innerWidth) {
      item.setAttribute("style", "");
    }
  };
}
