var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content-block-outlined"},[_vm._m(0),_vm._v(" "),_c('form',{staticClass:"direct-mail",on:{"submit":function($event){$event.preventDefault();return _vm.notify.apply(null, arguments)}}},[_c('div',{staticClass:"messaging",class:{
                        success: _vm.notifyStatus === 'success',
                        error: _vm.notifyStatus === 'error'
                    }},[(_vm.notifyStatus === 'success')?_c('p',[_vm._v("\n                    Your results have been sent!\n                ")]):_vm._e(),_vm._v(" "),(_vm.notifyStatus === 'error')?_c('p',[_vm._v("\n                    There has been an error sending your request. Please contact Forth\n                ")]):_vm._e()]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"name",attrs:{"type":"text","placeholder":"Name","required":"required"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"email",attrs:{"type":"email","placeholder":"Email","required":"required"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('label',{staticClass:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newsletter),expression:"newsletter"}],attrs:{"type":"checkbox","value":"1","name":"newsletter"},domProps:{"checked":Array.isArray(_vm.newsletter)?_vm._i(_vm.newsletter,"1")>-1:(_vm.newsletter)},on:{"change":function($event){var $$a=_vm.newsletter,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.newsletter=$$a.concat([$$v]))}else{$$i>-1&&(_vm.newsletter=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.newsletter=$$c}}}}),_vm._v(" "),_c('div',{staticClass:"toggle-indicator"}),_vm._v("\n                Yes! Send me news from Forth on the future of getting around.\n            ")]),_vm._v(" "),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"content-default"},[_c('h2',{staticClass:"heading-secondary"},[_vm._v("Email me my results.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"submit",attrs:{"type":"submit"}},[_c('span',{staticClass:"screen-reader-text"},[_vm._v("Submit email form")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"submit narrow",attrs:{"type":"submit"}},[_c('span',{staticClass:"screen-reader-text"},[_vm._v("Submit email form")])])
}]

export { render, staticRenderFns }