<template>
    <div>
      <component
        :is="type"
        v-if="question"
        :question="question"
        :questionData="questionData"
        :selected="selected"
        :onAnswer="updateAnswer"
      >
      </component>
      <button
        v-if="valid"
        class="next-button"
        @click="submitAnswer"
        v-bind:class="{
            invisible: !valid
        }"
      >
          {{submitText}}
      </button>
    </div>
</template>
<script>
    import RadioQuestion from './RadioQuestion.vue';
    import SelectQuestion from './SelectQuestion.vue';

    export default{
      components: {
        RadioQuestion,
        SelectQuestion
      },
      props: {
        type: String,
        question: Object,
        questionData: Object,
        onSubmit: Function,
        ordinal: Number,
        selected: Object
      },
      data: function() {
        return {
          valid: false,
          answer: null,
        }
      },
      computed: {
        submitText: function() {
          let text = "Next";
          if (this.question.query.nextLabel) {
            text = this.question.query.nextLabel;
          }

          return text;
        }
      },
      watch: {
        question(next, prev) {
          // Update validity and answer when changing questions
          if (next !== prev) {
            this.answer = this.selected;
            this.valid = this.answer ? true : false;
          }
        }
      },
      methods: {
        updateAnswer: function(answer) {
          // Update answer and validity
          this.answer = answer;
          this.valid = answer ? true : false;
        },
        submitAnswer: function() {
          // Run form submit function with answer
          this.onSubmit(this.ordinal, this.answer);
        }
      }
    }
</script>
