<template>
    <div class="question">
        <h3 class="query"> {{question.query.text}} </h3>
        <nav>
            <ul class="answers">
                <li v-for="(answer, index) in question.answers">
                    <radio-answer
                        v-bind:answer="answer"
                        v-bind:onAnswer="setSelectedFromButton"
                        v-bind:id="index"
                        v-bind:activeId="selectedParent"
                        v-bind:activeChild="selectedChild"
                    >
                  </radio-answer>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
    import RadioAnswer from './RadioAnswer.vue';

    export default{
      components: {
        RadioAnswer
      },
      props: {
        question: Object,
        selected: [Number, Object],
        onAnswer: Function,
      },
      data: function() {
        return {
          selectedParent: null,
          selectedChild: null
        };
      },
      mounted: function() {
        this.setSelectedFromProps();
      },
      watch: {
        question(next, prev) {
          // Empty selected and replace with props when changing questions
          if (next !== prev) {
            this.setSelectedFromProps();
          }
        },
        selectedParent: function() {
          this.buildAnswer();
        },
        selectedChild: function() {
          this.buildAnswer();
        }
      },
      methods: {
        setSelectedFromProps: function() {
          // Set selected data from app props
          this.selectedParent = null;
          this.selectedChild = null;

          if (this.selected) {
            this.selectedParent = this.selected.parent;
            this.selectedChild = this.selected.child;

            this.buildAnswer();
          }
        },
        setSelectedFromButton: function(index, childId) {
          this.selectedParent = index;
          this.selectedChild = childId !== null ? childId : null;
        },
        buildAnswer: function() {
          const parent = this.selectedParent;
          const childId = this.selectedChild;

          const answer = this.question.answers[parent];

          // If there's a selected answer
          if (answer) {
            // Assemble an answer object with the radio, filter data and
            // pass it back to the question wrapper
            let filters = [];
            let modes = [];

            if (answer.filters) {
              filters = filters.concat(answer.filters);
            } else if (childId && childId.filters) {
              filters = filters.concat(answer.filters);
            }

            if (answer.modes) {
              modes = modes.concat(answer.modes);
            }

            this.onAnswer({
              id: parent,
              childId,
              filters,
              modes
            });
          }
        }
      }
    }
</script>
