<template>
    <div>
        <section v-if="!showResults" class="match-survey">
            <div class="container">
                <question
                    :type="questionType"
                    :question="questions[current]"
                    :questionData="questionData"
                    :onSubmit="answer"
                    :ordinal="current"
                    :selected="answers[current]"
                >
              </question>
            </div>
        </section>

        <figure v-if="loading" class="match-loader">
            <img :src="loader">
        </figure>

        <results v-if="showResults"
            :config="config"
            :vehicles="vehicles"
            :used="used"
            :url="resultsUrl"
        ></results>

        <div class="container section-spacing" v-if="debug">
            <section class="content-default bg-neutral20">
                <h4>Debug</h4>
                <ul>
                    <li>
                        Config: {{ config }}
                    </li>
                    <li>
                        Answered: {{ last }}
                    </li>
                    <li>
                        Answers: {{ answers }}
                    </li>
                    <li>
                        Filters: {{ filters }}
                    </li>
                    <li>
                      Modes: {{ modes }}
                    </li>
                </ul>
            </section>
        </div>
    </div>

</template>

<script>
  import Question from './Question.vue';
  import domHelpers from '../dom-help.js';
  import Results from './Results.vue';
  import get from 'lodash/get';

  export default{
    data() {
      return {
        loading: false,
        last: null,
        answers: [],
        filters: [],
        modes: [],
        surveyResults: null,
        vehicles: null,
        resultsUrl: null,
        // Set to true to debug values and submit dummy data
        debug: false
      }
    },
    computed: {
      // Always returns array level (start at 0) current question
      current: function() {
        let output = 0;
        if (this.$route.params.ordinal &&
          this.$route.params.ordinal > 0) {
          // If there's a page route, subtract one to use
          // count level (start at 1)
          const routeIndex = parseInt(this.$route.params.ordinal) - 1;
          if (this.last !== null && this.last + 1 >= routeIndex) {
            output = routeIndex;
          } else if (this.last) {
            // Or take the user back to the latest (and update the router)
            // if the user hasn't gotten to that question yet
            output = this.last + 1;
            this.$router.replace('/question/' + (this.last + 2));
          } else {
            // The route doesn't exist, set it to 1
            this.$router.replace('/question/' + (output + 1));
          }
        }

        return output;
      },
      questionType: function() {
        let questionType = 'radio-question';
        if (this.questions[this.current] && this.questions[this.current].select) {
          questionType = 'select-question';
        }
        return questionType;
      },
      showNext: function() {
        return this.current + 1 < this.questions.length &&
          this.last !== null && this.last >= this.current;
      },
      showSubmit: function() {
        return this.current + 1 === this.questions.length &&
          this.last >= this.current;
      },
      showResults: function() {
        return this.$route.path.indexOf('/results') > -1 &&
          this.surveyResults !== null;
      }
    },
    props: {
      questionData: Object,
      config: Object,
      questions: Array,
      loader: String
    },
    watch: {
      current: function (val) {
        const startOverButtons = document.querySelectorAll('.start-over-button');

        if (startOverButtons.length < 1) { return; }
        if (val >= 1) {
          [...startOverButtons].forEach(function(button) {
            domHelpers.addClass(button, 'active');
          });
        } else {
          [...startOverButtons].forEach(function(button) {
            domHelpers.removeClass(button, 'active');
          });
        }
      }
    },
    methods: {
      setLast: function(index) {
        if (!this.last && index === 0) {
          this.last = index;
        }
        if (index > this.last) {
          // Keep track of the last question that was answered.
          // User can only view questions that are <= last + 1
          this.last = index;
        }
      },
      answer: function(index, answer) {
        // Add answer to array if there's a child (default is null)
        this.answers.splice(
          index,
          1,
          { parent: answer.id, child: answer.childId }
        );

        if (answer.filters) {
          // Replace filters at this index
          this.filters.splice(index, 1, answer.filters);
        }
        if (answer.modes) {
          // Replace modes at this index
          this.modes.splice(index, 1, answer.modes);
        }

        // Keep track of the last question answered
        this.setLast(index);
        this.$router.push('/question/' + (index + 2));

        // If survey is done, also submit it!
        if ((index + 1) === this.questions.length) this.submitFilters();
      },
      concatenateArrays: function(filters) {
        let combined = [];
        filters.forEach(function(list) {
          combined = combined.concat(list);
        });

        return combined;
      },
      getCars: function(filters, modes, onSuccess, onError) {
        const request = new XMLHttpRequest();
        let apiUrl = `/api/v1/vehicles?filters=${filters}`;
        if (modes.length > 0) {
          apiUrl = apiUrl + `&${modes}`;
        }
        request.open('GET', apiUrl, true);

        request.onload = function() {
          if (request.status >= 200 && request.status < 400) {
            // Success
            onSuccess(JSON.parse(request.responseText));
          } else {
            console.log('Server was reached, but it returned an error');
          }
        };

        request.send();
      },
      getResults: function(filters, modes) {
        // Show loader until AJAX is done
        this.loading = true;
        const filterString = this.concatenateArrays(filters).toString();
        const modesString = this.concatenateArrays(modes).join('&');

        // Get vehicles from the API and assign them to survey data
        this.getCars(
          encodeURIComponent(filterString),
          encodeURIComponent(modesString),
          (data) => {
            // Success function
            this.$router.push({ path: '/results' });
            this.surveyResults = data;
            this.vehicles = data.vehicles;
            this.used = data.query.used;
            this.resultsUrl = data.resultsUrl.web;
            this.loading = false;
          });
      },
      submitFilters: function() {
        let filters = this.filters;
        // Check if there are predefined filters
        if (get(this.config, 'init.filters')) {
          filters = this.config.init.filters.concat(filters);
        }

        this.getResults(filters, this.modes)
      },
      submitDummy: function() {
        this.getResults([
          [],
          [],
          ["combined-range:200"],
          ["combined-range:200"],
          [],
          ["combined-range:100"],
          ["seats:5:"],
          [],
          ["msrp:31000:40999"]
        ]);
      }
    },
    components: {
      Question,
      Results
    },
  }
</script>
