<template>
    <div class="answer" :class="{active: activeId === id || childrenVisible}">
        <button v-if="!answer.childAnswers && !answer.reveals" class="parent" v-on:click="answerQuestion()">
            {{ answer.text }}
        </button>

        <button v-if="answer.childAnswers || answer.reveals" class=parent v-on:click="showChildren()">
            {{ answer.text }}
        </button>

        <div v-if="childrenVisible">
            <component :is="answer.reveals"></component>

            <ul class="child-answers">
                <li
                    v-for="(child, index) in answer.childAnswers"
                    :class="{active: activeChild === index}"
                >
                    <button class="child"
                        v-on:click="answerQuestion(index)">
                        {{ child.text }}
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
      props: {
        answer: Object,
        onAnswer: Function,
        id: Number,
        activeId: Number,
        activeChild: Number,
      },
      data() {
        return {
          childrenVisible: false,
        }
      },
      watch: {
        answer: function() {
          this.updateChildren();
        },
        activeId: function(val) {
          if ((this.answer.childAnswers || this.answer.reveals) &&
                val !== this.id) {
            this.childrenVisible = false;
          }
        }
      },
      mounted: function() {
        this.updateChildren();
      },
      methods: {
        showChildren: function() {
          this.childrenVisible = true;
        },
        updateChildren: function() {
          if (this.answer.childAnswers && this.activeChild !== null) {
            this.childrenVisible = true;
          } else {
            this.childrenVisible = false;
          }
        },
        answerQuestion: function(childId = null) {
          this.onAnswer(this.id, childId)
        }
      }
    }
</script>
