export default class ExternalLinks {
  constructor() {
    document.addEventListener("click", this.clickCheck);
  }

  clickCheck(event) {
    const link = event.target;

    if (link.hostname && link.hostname !== window.location.hostname) {
      event.preventDefault();
      event.stopPropagation();

      window.open(link.href, "_blank");
    }
  }
}
