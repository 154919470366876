<template>
    <div class="question">
        <h3 class="query"> {{question.query.text}} </h3>
        <div class="answer-select">
          <select
            v-if="options"
            v-model="selectedOption"
          >
            <option
              :value="null"
              disabled
            >
              Pick a state
            </option>
            <option
              v-for="name, value in options"
              :value="value"
            >
              {{name}}
            </option>
          </select>
        </div>
        <div
          class="children"
          v-if="this.childQuestions"
        >
          <ul class="sub-questions">
            <li v-for="(question, index) in this.childQuestions">
              <h4 class="query">
                {{question.query.text}}
              </h4>
              <ul class="answers">
                <li v-for="(answer, subIndex) in question.answers">
                    <radio-answer
                      :answer="answer"
                      :onAnswer="(answer)=> { updateSubAnswer(index, answer) }"
                      :id="subIndex"
                      :activeId="childAnswers[index]"
                    >
                  </radio-answer>
                </li>
              </ul>
            </li>
          </ul>
        </div>
    </div>
</template>
<script>
  import RadioAnswer from './RadioAnswer.vue';

  export default{
    components: {
      RadioAnswer
    },
    props: {
      question: Object,
      questionData: Object,
      selected: Object,
      onAnswer: Function,
    },
    data: function() {
      return {
        childQuestions: null,
        selectedOption: null,
        childAnswers: [],
      }
    },
    computed: {
      options: function() {
        if (!this.questionData[this.question.select.dataSource]) return null;
        return this.questionData[this.question.select.dataSource];
      }
    },
    mounted: function() {
      this.setSelectedFromProps();
    },
    watch: {
      question: function(next, prev) {
        // Empty selected and replace with props when changing questions
        if (next !== prev) {
          this.setSelectedFromProps();
        }
      },
      selectedOption: function() {
        const select = this.question.select;
        // Submit answer to wrapper question if there is no child,
        let childQuestions = null;

        // Check if selected option matches any child question
        if (select.childQuestions && select.childQuestions.length > 0) {
          childQuestions = select.childQuestions.filter((question) => {
            return question.conditions.some((item) => {
              return item === this.selectedOption
            });
          });
        }

        this.childQuestions = childQuestions;

        if (
          childQuestions.length <= 0 ||
          (
            childQuestions.length > 0 &&
            this.childAnswers.length === childQuestions.length
          )
        ) {
          // Submit answer if valid
          this.buildAnswer();
        } else {
          // Invalidate parent wrapper
          this.onAnswer(null);
        }
      },
      childAnswers: function() {
        if (this.childAnswers.length > 0) {
          if (this.childAnswers.length === this.childQuestions.length) {
            // Build a valid answer
            this.buildAnswer();
          } else {
            // Invalidate parent wrapper
            // NB: Currently there's only one question,
            // so this should never happen
            this.onAnswer(null);
          }
        }
      }
    },
    methods: {
      setSelectedFromProps: function() {
        this.selectedOption = null;
        this.childAnswers = [];

        if (this.selected) {
          this.selectedOption = this.selected.parent;
          this.childAnswers =
            this.selected.child !== null ? [this.selected.child] : [];
        }
      },
      updateSubAnswer: function(index, answer) {
        // Just update the child answers
        // The watcher will update validity
        this.childAnswers.splice(index, 1, answer);
      },
      buildAnswer: function() {
        const select = this.question.select;
        // Start a filters array with the parent filter
        let filters = [`${select.filter}:${this.selectedOption}`];

        if (this.childQuestions) {
          // Then add any child answer filters there are
          this.childQuestions.forEach((question, index) => {
            const childAnswer = question.answers[this.childAnswers[index]];
            if (childAnswer.filters) {
              filters = filters.concat(childAnswer.filters);
            }
          });
        }

        // Submit a valid answer
        // NB: Child id selected functions will have to be refactored if
        // more than one child question is needed
        this.onAnswer({
          id: this.selectedOption,
          childId: this.childAnswers.length > 0 ? this.childAnswers[0] : null,
          filters
        });
      }
    }
  }
</script>
