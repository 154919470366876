<template>
    <section class="match-results">
        <section v-if="vehicles">
            <div class="success">
                <div class="container">
                    <div class="content-default">
                        <template v-if="version == 'ca'">
                            <p v-if="used" class="heading-secondary accent">
                                Eureka! We’ve got just the cars for you. For your price range, we recommend one of the
                                used cars below. Fill out the form at the bottom of the list to receive this list via
                                email.
                            </p>
                            <p v-else class="heading-secondary accent">
                                Eureka! We’ve got just the cars for you. Fill out the form at the bottom of the list
                                to receive this list via email.
                            </p>
                        </template>
                        <template v-else>
                            <p v-if="used" class="heading-secondary accent">
                                Eureka! We’ve got just the cars for you. For your price range, we recommend one of the used cars below.
                                Check out the specs below and <a href="/contact">contact us</a> when you’re ready to get behind the wheel!
                            </p>
                            <p v-else class="heading-secondary accent">
                                Eureka! We’ve got just the cars for you. Check out the specs below and
                                <a href="/contact">contact us</a> when you’re ready to get behind the wheel!
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <div class="container">
                <ul class="vehicle-list">
                    <li v-for="vehicle in vehicles"
                        class="vehicle"
                    >
                        <a
                            v-if="vehicle.in_fleet"
                            class="circle-link narrow"
                            :href="vehicle.test_drive_url"
                            target="_blank"
                        >
                            <span>Test Drive<br/>This<br/>Model</span>
                        </a>
                        <figure
                            v-if="vehicle.image_900w"
                            class="narrow"
                        >
                            <img :src="vehicle.image_900w">
                        </figure>
                        <scroll-show
                                class="border-container right"
                                :threshold="20"
                        >
                            <div class="a-border-top"></div>
                            <div class="a-overhang"></div>
                        </scroll-show>
                        <div class="wrapper">
                            <a
                                v-if="vehicle.in_fleet && showTestDrive"
                                class="circle-link"
                                :href="vehicle.test_drive_url"
                                target="_blank"
                            >
                                <span>Test Drive<br/>This<br/>Model</span>
                            </a>
                            <header>
                                <h2>{{ vehicle.make_name }} {{ vehicle.model }}</h2>
                            </header>
                            <div class="body">
                                <ul class="stats"
                                    :class="{image: vehicle.image_900w}"
                                >
                                    <li v-if="used">
                                        <strong>Average Used Price: </strong>${{ addCommas(vehicle.avg_used_price) }}
                                    </li>
                                    <template v-else-if="vehicle.is_lease_only">
                                        <li>
                                            <strong>Note: this vehicle is only available for lease.</strong>
                                        </li>
                                        <li>
                                            <strong>Average Lease Down Payment: </strong>${{ addCommas(vehicle.avg_down) }}
                                        </li>
                                        <li>
                                            <strong>Average Lease Monthly Payment: </strong>${{ addCommas(vehicle.avg_monthly) }}
                                        </li>
                                    </template>
                                    <li v-else>
                                        <strong>Price: </strong>${{ addCommas(vehicle.base_msrp) }}
                                    </li>
                                    <li>
                                        <strong>Technology: </strong>{{ vehicle.technology_name }}
                                    </li>
                                    <li v-if="vehicle.gas_range > 0">
                                        <strong>Combined Range: </strong>{{ vehicle.combined_range }} miles
                                    </li>
                                    <li v-else>
                                        <strong>Electric Range: </strong>{{ vehicle.elec_range }} miles
                                    </li>
                                    <li v-if="vehicle.gas_range > 0">
                                        <strong>Electric Range: </strong>{{ vehicle.elec_range }} miles
                                    </li>
                                    <li v-if="vehicle.gas_range > 0">
                                        <strong>Gas Range: </strong>{{ vehicle.gas_range }} miles
                                    </li>
                                    <li>
                                        <strong>Seats: </strong>{{ vehicle.seats_range }}
                                    </li>
                                    <li>
                                        <strong>Body Style: </strong>{{ vehicle.body_style_name }}
                                    </li>
                                    <li>
                                        <strong>Cargo Space: </strong>{{ vehicle.cargo_space }} ft.<sup>3</sup>
                                    </li>
                                    <li>
                                        <strong>Charge Time: </strong>{{ vehicle.charge_time_range }}
                                    </li>
                                    <li>
                                        <strong>Fast Charger: </strong><span v-if="vehicle.dcfc">Yes</span><span v-else>No</span>
                                    </li>
                                    <li v-if="vehicle.url">
                                        <a target="_blank" :href="vehicle.url"><strong>Visit Website</strong></a>
                                    </li>
                                    <li v-if="vehicle.body" class="description content-default">
                                        {{ vehicle.body }}
                                    </li>
                                </ul>
                                <figure v-if="vehicle.image_900w">
                                    <img :src="vehicle.image_900w">
                                </figure>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <section class="container narrow">
                <match-notify
                    :vehicleSlugs="vehicleSlugs"
                    :config="config"
                    :used="used"
                >
                </match-notify>
            </section>

            <div class="container section-spacing">
                <nav class="circle-link-nav">
                    <ul>
                        <li v-if="showTestDrive">
                            <a href="https://squareup.com/appointments/book/934B49XT19ZHP/forth">
                                Reserve a Test Drive
                            </a>
                        </li>
                        <li v-if="showWattplan">
                            <a href="https://www.wattplan.com/ev/?source=forth">
                                Calculate savings
                            </a>
                        </li>
                        <li>
                            <a href="/contact">
                                Get in Touch with Forth
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="container align-center button-restart">
                <a class="button-primary accent-secondary" href="/drive-finder">
                    Start Over
                </a>
            </div>
        </section>
    </section>
</template>
<script>
  import domHelpers from '../dom-help.js';
  import MatchNotify from './MatchNotify.vue';
  import ScrollShow from '../ScrollShow.vue';
  import get from 'lodash/get';

  export default{
    props: {
      config: Object,
      vehicles: Array,
      used: Boolean,
      url: String
    },
    components: {
      ScrollShow,
      MatchNotify
    },
    computed: {
      vehicleSlugs: function() {
        return this.url;
      },
      showTestDrive: function() {
        // Default property is true
        let property = true;

        // Don't show test drive if false in JSON
        if (get(this.config, 'display.testDrive') !== undefined) {
          property = this.config.display.testDrive;
        }

        return property;
      },
      showWattplan: function() {
        // Default property is true
        let property = true;

        // Don't show test drive if false in JSON
        if (get(this.config, 'display.wattplan') !== undefined) {
          property = this.config.display.wattplan;
        }

        return property;
      },
      version: function() {
        if (get(this.config, 'version') !== undefined) {
          return this.config.version;
        }
        return '';
      }
    },
    methods: {
      addCommas: function(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    mounted: function() {
      const startOverButtons = document.querySelectorAll('.start-over-button');

      if (startOverButtons.length > 0) {
        [...startOverButtons].forEach(function(button) {
          domHelpers.addClass(button, 'active');
        });
      }
    }
  }
</script>
