<template>
    <div
        ref="el"
        v-bind:class="{
            'on-screen': onScreen,
            'off-screen': !onScreen
        }"
    >
        <slot>
        </slot>
    </div>
</template>
<script>
  import throttle from 'lodash/throttle';

  export default{
    props: {
      threshold: Number,
    },
    data() {
      return {
        onScreen: false,
        updateOnScroll: null
      }
    },
    mounted: function() {
      // Establish scrolling update routine variable that can be called
      // when unmounted
      this.updateOnScroll = throttle(() => {
        // Get bounding box of element
        this.updateAppearance();
      }, 200);

      // Display elements if they are on the screen
      this.updateAppearance();

      // Then subsequently show/hide them on scroll
      document.addEventListener('scroll', this.updateOnScroll);
    },
    beforeDestroy: function() {
      document.removeEventListener('scroll', this.updateOnScroll);
    },
    methods: {
      updateAppearance: function() {
        // Get bounding box of element
        const rect = this.$refs.el.getBoundingClientRect();

        // eslint-disable max-len
        if (
          rect.top - this.threshold > 0 &&
          rect.top + this.threshold < window.innerHeight
        ) {
          // eslint-enable max-len
          this.onScreen = true;
        } else {
          if (this.onScreen === true) {
            this.onScreen = true;
          } else {
            this.onScreen = false;
          }
        }
      }
    }
  }
</script>
