<template>
  <div class="slider">
    <ul v-bind:style="{transform: 'translateX(' + currentSlide * -100 + '%)'}">
      <li v-for="(slide, index) in slides">
        <a v-bind:href="slide.link" target="_blank">
          <div class="slide" v-bind:style="{backgroundImage: 'url(/storage/app/media' + slide.path + ')'}"  >
            <div class="caption">{{slide.caption}}</div>
          </div>

        </a>
      </li>
    </ul>

    <nav class="slider-nav">
      <div v-on:click="slidePrevious" class="left"></div>
      <div v-on:click="slideNext" class="right"></div>
    </nav>
  </div>
</template>

<script>
  export default {
    props: {
      slides: Object
    },
    data() {
      return {
        currentSlide: 0,
        interval: null
      };
    },
    computed: {
      lastIndex: function() {
        return this.lastSlideIndex();
      }
    },
    methods: {
      slideNext: function () {
        if (this.currentSlide >= this.lastIndex) {
          this.currentSlide = 0;
        } else {
          this.currentSlide += 1;
        }
      },

      slidePrevious: function () {
        if (this.currentSlide <= 0) {
          this.currentSlide = this.lastIndex;
        } else {
          this.currentSlide -= 1;
        }
      },

      lastSlideIndex: function () {
        return Object.keys(this.slides).length - 1;
      }
    },
    created: function() {
      // this.interval = setInterval(() => {
      //   if (this.currentSlide >= this.lastSlideIndex()) {
      //     this.currentSlide = 0;
      //   } else {
      //     this.currentSlide += 1;
      //   }
      // }, 5000);
    },
    beforeDestroy: function() {
      clearInterval(this.interval);
    }
  }
</script>
