const SESSION_KEY = "hasDismissedModal";

export default class Modal {
  constructor() {
    this.modal = document.querySelector('[data-ref="modal"]');
    this.closeButton = document.querySelector('[data-ref="modal-close"]');

    if (!this.modal || !this.closeButton) return;

    this.loadModal = this.loadModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);

    // don't load modal if user has already dismissed it
    // (unless UA doesn't support sessionStorage or blocks access to it)
    try {
      this.session = window.sessionStorage.getItem(SESSION_KEY);
    } catch (error) {
      return this.loadModal();
    }

    if (this.session) return null;

    this.loadModal();
  }

  loadModal() {
    this.modal.classList.add("is-open");
    document.body.classList.add("modal-is-open");
    this.closeButton.focus();
    this.closeButton.addEventListener("click", this.handleClick);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  handleClick(event) {
    event.preventDefault();
    this.closeModal();
    this.closeButton.removeEventListener("click", this.handleClick);
  }

  handleKeyUp(event) {
    if (event.keyCode !== 27) return null;
    event.preventDefault();
    this.closeModal();
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  closeModal() {
    this.modal.classList.remove("is-open");
    document.body.classList.remove("modal-is-open");
    try {
      window.sessionStorage.setItem(SESSION_KEY, true);
    } catch (error) {}
  }
}
