import domHelpers from "./dom-help";
export default class InlinePdf {
  constructor() {
    this.embedOrDownloadPdf();
  }

  get isIOSDevice() {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  }

  embedOrDownloadPdf() {
    const embed = document.querySelectorAll("#pdf-inline")[0];
    const download = document.querySelectorAll("#pdf-download")[0];

    function getActiveXObject(name) {
      try {
        return new ActiveXObject(name); // eslint-disable-line no-undef
      } catch (e) {}
    }

    const hasPDFSupport =
      navigator.pdfViewerEnabled ||
      getActiveXObject("AcroPDF.PDF") ||
      getActiveXObject("PDF.PdfCtrl");

    if (this.isIOSDevice || !hasPDFSupport) {
      domHelpers.addClass(embed, "qq-invisible");
      domHelpers.removeClass(download, "qq-invisible");
    }
  }
}
