<template>
    <div>
        <h2 class="sign-tertiary marquee-text">
            {{ preface }}
            <transition :duration="2000" name="marquee">
                <span class="marquee-dynamic" v-bind:key="lines[current]">
                    {{ lines[current] }}
                </span>
            </transition>
        </h2>
    </div>
</template>

<script>
    export default {
      props: {
        preface: String,
        lines: Array
      },
      data() {
        return {
          current: 0,
          max: this.lines.length - 1,
          interval: null
        }
      },

      created: function() {
        this.interval = setInterval(() => {
          if (this.current + 1 <= this.max) {
            this.current += 1
          } else {
            this.current = 0;
          }
        }, 3000);
      },
      beforeDestroy: function() {
        clearInterval(this.interval);
      }
    }
</script>
