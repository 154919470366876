import Vue from "vue";
import VueRouter from "vue-router";
import fluidvids from "fluidvids.js";
import Headroom from "./lib/headroom.min.js";
import Maps from "./Maps.js";
import ExternalLinks from "./external-links.js";
import InlinePdf from "./inline-pdf";
import ScrollShow from "./ScrollShow.vue";
import HeroMarquee from "./HeroMarquee.vue";
import Slider from "./Slider.vue";
import Survey from "./Match/Survey.vue";
import NavDropdown from "./NavDropdown.js";
import Modal from "./modal.js";

export default class ForthTheme {
  init() {
    // Set headroom on the header
    const siteHeader = document.querySelector("[data-headroom-header]");
    const headroom = new Headroom(siteHeader, {
      offset: 200,
    });

    const modal = new Modal();

    headroom.init();

    // Open 'data-links' with JS to prevent hopping out of
    // a homescreen web-app
    [...document.querySelectorAll("[data-link]")].forEach((link) => {
      const url = link.dataset.link;
      link.addEventListener("click", (event) => {
        event.preventDefault();
        document.location.href = url;
      });
    });

    // Use Vue router plugin
    Vue.use(VueRouter);

    // Initialize the theme
    if (document.querySelectorAll(".hero-app").length) {
      const heroApp = new Vue({
        el: ".hero-app",
        components: {
          HeroMarquee,
        },
      });
    }
    if (document.querySelectorAll("#header-app").length) {
      const headerUtility = new Vue({
        el: "#header-app",
        data: {
          hamburger: false,
          search: false,
        },
        methods: {
          openSearch: function () {
            this.search = true;
          },
          closeSearch: function () {
            this.search = false;
          },
          closeHamburger: function () {
            this.hamburger = false;
          },
          openHamburger: function () {
            this.hamburger = true;
          },
        },
      });
    }

    if (document.querySelectorAll("#pdf-inline").length) {
      if (document.readyState !== "loading") {
        const pdf = new InlinePdf();
      } else {
        document.addEventListener("DOMContentLoaded", new InlinePdf());
      }
    }

    // Create a vue app for every element with class .scroll-app
    if (document.querySelectorAll(".scroll-app").length) {
      const scrollApps = document.querySelectorAll(".scroll-app");
      [...scrollApps].forEach(function (app) {
        const component = new Vue({
          el: app,
          components: {
            ScrollShow,
            Slider,
          },
        });
      });
    }

    const maps = new Maps();
    maps.instantiateCallback("[data-map]");

    // Initialize Fluid Vids
    fluidvids.init({
      selector: ["iframe", "object"], // runs querySelectorAll()
      players: ["www.youtube.com", "player.vimeo.com"], // players to support
    });

    if (document.querySelector("#match-app")) {
      const matchRouter = new VueRouter({
        routes: [{ path: "/question/:ordinal", component: Survey }],
      });
      // Setup Match Application
      const match = new Vue({
        el: "#match-app",
        components: {
          Survey,
        },
        data: {
          questionData: window.matchData,
          config: window.matchConfig,
          questions: window.matchQuestions,
        },
        router: matchRouter,
      });
    }

    // Add dropdown behavior to data-nav-dropdown elements
    if (document.querySelectorAll("[data-nav-dropdown]").length) {
      const els = document.querySelectorAll("[data-nav-dropdown]");
      [...els].forEach(function (el) {
        const dropdown = new NavDropdown(el);
      });
    }

    if (document.readyState !== "loading") {
      const links = new ExternalLinks();
    } else {
      document.addEventListener("DOMContentLoaded", new ExternalLinks());
    }
  }
}
