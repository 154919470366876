// Simple maps class that instantiates a callback
// required by GoogleMaps for styling, markers, etc.

// API:
// - In HTML, give a div the data attribute passed
// to the instantiate callback function (i.e. data-map)
// - Give the div 'data-lat' and 'data-long' attributes for
// centering

export default class Maps {
  // Instantiate the callback used by google maps
  instantiateCallback(selector) {
    // Create a style array to be used
    // in all maps
    const mapStyles = [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ];

    window.initMap = function () {
      const maps = document.querySelectorAll(selector);
      [...maps].forEach(function (mapEl) {
        const location = {
          lat: parseFloat(mapEl.getAttribute("data-lat")),
          long: parseFloat(mapEl.getAttribute("data-long")),
        };

        const styles = new window.google.maps.StyledMapType(mapStyles, {
          name: "chrome",
        });

        const map = new window.google.maps.Map(mapEl, {
          center: { lat: location.lat, lng: location.long },
          zoom: 15,
          terrain: false,
          controls: false,
          streetViewControl: false,
          mapTypeControl: false,
          mapTypecontrolOptions: {
            mapTypeIds: ["chrome"],
          },
        });

        const marker = new window.google.maps.Marker({
          position: { lat: location.lat, lng: location.long },
          icon: "themes/forth/assets/images/map-marker.png",
          map: map,
        });

        if (mapEl.getAttribute("data-link")) {
          marker.addListener("click", function () {
            window.open(mapEl.getAttribute("data-link"), "_blank");
          });
        }

        // Associate the styled map with the MapTypeId and set it to display.
        map.mapTypes.set("chrome", styles);
        map.setMapTypeId("chrome");
      });
    };
  }
}
