export default {
  hasClass: function (el, className) {
    if (el.classList) {
      return el.classList.contains(className);
    } else {
      return !!el.className.match(
        new RegExp("(\\s|^)" + className + "(\\s|$)")
      );
    }
  },

  addClass: function (el, className) {
    if (el.classList) {
      el.classList.add(className);
    } else if (!this.hasClass(el, className)) {
      el.className += " " + className;
    }
  },

  removeClass: function (el, className) {
    if (el.classList) {
      el.classList.remove(className);
    } else if (this.hasClass(el, className)) {
      const reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
      el.className = el.className.replace(reg, " ");
    }
  },

  ready: function (f) {
    if (document.readyState !== "loading") {
      f();
    } else {
      document.addEventListener("DOMContentLoaded", f);
    }
  },
};
